
.d-flex {
    display: flex;
}

.f-wrap {
    flex-wrap: wrap;
}

.f-d-column {
    flex-direction: column;
}

.f-d-column-reverse {
    flex-direction: column-reverse;
}

.f-d-row {
    flex-direction: row;
}

.a-i-center {
    align-items: center;
}
.a-i-end {
    align-items: flex-end;
}

.j-c-center{
    justify-content: center;
}

.j-c-space-between {
    justify-content: space-between;
}

.j-c-right,
.j-c-end {
    justify-content: flex-end;
}

.all-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
