.w-100 {
    width: 100% !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
}

.min-w-33 {
    min-width: 33vw;
}

.h-100 {
    height: 100% !important;
    max-height: 100% !important;
}

.p-1 {
    padding: 4px !important;
}

.p-2 {
    padding: 8px !important;
}

.p-3 {
    padding: 12px !important;
}

.px-1 {
    padding: 0 4px !important;
}

.pl-1 {
    padding: 0 4px 0 0 !important;
}

.pb-3 {
    padding: 0 0 12px 0 !important;

}

.pb-2 {
    padding: 0 0 8px 0 !important;

}

.pb-1 {
    padding: 0 0 4px 0 !important;

}

.pt-2 {
    padding: 8px 0 0 0 !important;

}

.pt-3 {
    padding: 12px 0 0 0 !important;

}

.px-2 {
    padding: 0px 8px !important;
}

.px-3 {
    padding: 0px 12px !important;
}

.py-1 {
    padding: 4px 0px !important;
}

.py-2 {
    padding: 8px 0px !important;
}

.py-3 {
    padding: 12px 0px !important;
}

.pr-2 {
    padding-right: 8px;
}

.mx-1 {
    margin: 0px 4px !important;
}

.mx-2 {
    margin: 0px 8px !important;
}

.mx-3 {
    margin: 0px 12px !important;
}

.my-1 {
    margin: 4px 0px !important;
}

.my-2 {
    margin: 8px 0px !important;
}

.my-3 {
    margin: 12px 0px !important;
}

.mt-3 {
    margin-top: 12px;
}

.mr-2 {
    margin-right: 8px;
}

.separator {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 8px;

    span {
        margin: 0 4px;
        font-size: .9rem;
    }

    .horizontal-line {
        width: 20%;
        border-bottom: 1px solid  #748499;
    }
}

.icon-container {
    display: flex;
    align-items: center;
    ion-icon {
        margin-right: 4px;
    }
}

.box-shadow {
    margin-bottom: 0.6rem;
    -webkit-box-shadow: 0px 8px 6px 0px rgba(68, 68, 68, 0.1);
    box-shadow: 0px 8px 6px 0px rgba(68, 68, 68, 0.1);
}

.no-box-shadow {
    box-shadow: unset;
}

//////////////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////////////


@media only screen and (max-width:599.98px) {
    .d-only-mobile-block {
        display: block;
    }

    .d-only-moblie-flex {
        display: flex;
    }

    .d-only-web-block,
    .d-only-web-flex {
        display: none;
    }
}

@media only screen and (min-width:600px) {
    .d-only-web-block {
        display: block;
    }

    .d-only-web-flex {
        display: flex;
    }

    .d-only-mobile-block,
    .d-only-moblie-flex {
        display: none;
    }

}

@media only screen and (min-width: 1200px) {
    .d-none-lg {
        display: none;
    }
}
