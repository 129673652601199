input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: unset !important;
    color: #000 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 9999s ease-in-out 0s;
}

// After added aria-lable // fix inupts height


.input-container,
.floating-input-container {
    .sc-ion-input-md-h:not(.legacy-input) {
        min-height: unset;
    }

    ion-select:not(.legacy-select) {
        min-height: unset;
    }
}

.has-focus.input-fill-outline.input-label-placement-floating.sc-ion-input-md-h .label-text-wrapper.sc-ion-input-md,
.has-value.input-fill-outline.input-label-placement-floating.sc-ion-input-md-h .label-text-wrapper.sc-ion-input-md,
.input-fill-outline.input-label-placement-stacked.sc-ion-input-md-h .label-text-wrapper.sc-ion-input-md {
    transform: translateY(-32%) scale(0.9);
}

.ion-touched.ion-invalid,
ion-select.ng-touched.ng-invalid,
.input-fill-outline.ion-touched.ion-invalid.sc-ion-input-md-h {
    border-color: rgb(150, 0, 0) !important;
    // --border-color: #eee;
    --border-color: rgb(150, 0, 0) !important;
}

.ion-touched.ion-invalid,
.ion-touched.ion-invalid.sc-ion-input-md-h {
    // --highlight-color: #444;
    --highlight-color: rgb(150, 0, 0) !important;
}

.athletex-theme {
    form.form-container {
        .floating-input-container {
            ion-note {
                &.error {
                    // color: #444;
                    color: rgb(150, 0, 0);;
                }
            }
        }
    }
}

a {
    text-decoration: none;
}

.autocomplete-card-fix {
    contain: unset;
    overflow: unset;
}



