.athletex-theme {
    ion-grid {
        width: 100%;
    }

    ion-card {
        box-shadow: none;
        border-radius: 8px;
        border: 1px solid #eee;
    }

    ion-button {
        --box-shadow: none;
        font-size: 0.7rem;

        .button-processing {
            width: 12px;
            height: 12px;
        }
    }

    ion-select {
        --placeholder-opacity: 0.87;
    }

    h1 {
        font-weight: 600;
        font-size: 2.4rem;
    }

    h4 {
        font-weight: 600;
        font-size: 1.6rem;
    }

    p {
        font-size: 0.9rem;
        margin: 0.2rem 0;
    }

    .section-contrast {
        background: #f8f8f8;
        border-radius: 8px;
        border: 1px solid #eee;
        padding: 6px 12px;
        margin: 0 0 12px 0;
    }

    form.form-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        label.input-label {
            font-size: 0.8rem;
            color: #000;
            font-weight: 300;
        }

        ion-item.input-container {
            width: 100%;
            margin-bottom: 1rem;

            border-radius: 8px;
            border: 1px solid #eee;

            ion-note {
                position: absolute;
                bottom: -2px;
                right: 8px;
                z-index: 99;
                color: rgb(150, 0, 0);
            }

            ion-input {
                font-size: 0.8rem;

                --color: #000;
                --background: #fff;

                // &.input-label-placement-floating {
                //     font-size: 1.1rem;

                //     &.has-value .label-text-wrapper {
                //         -webkit-transform: translateY(20%) scale(0.75);
                //         transform: translateY(20%) scale(0.75);
                //     }
                // }
            }

            ion-select,
            ion-label {
                font-size: 0.8rem;
            }

            &.item-interactive {
                --border-width: 0;
            }

            ion-searchbar {
                padding: 0;
                --box-shadow: 0;
            }

            .input-error-message {
                position: absolute;
                bottom: -2px;
                right: 8px;
                font-size: 0.6rem;
                color: rgb(150, 0, 0);

                z-index: 99;

                display: none;
            }

            &.ion-invalid {
                // border-color: rgb(150, 0, 0) !important;

                .input-error-message {
                    display: block;
                }

                &.without-validation-border {
                    border-color: #eee !important;
                }
            }

            &.readonly {
                --background: #f5f5f5;

                ion-input {
                    --background: #f5f5f5;
                }
            }
        }

        .input-helper-message {
            display: block;
            margin: -0.9rem 0.1rem 0.9rem 0.1rem;

            font-size: 0.7rem;
            color: #777;

            text-align: start;
        }
    }
}

atx-autocomplete-picker {
    display: block;
    //margin: 0 0 12px 0;
}

.athlete-x-autocomplete-container {
    position: relative;

    border-radius: 8px;
    border: 1px solid #eee;

    padding: 0px 2px;
    line-height: 50px !important;
    height: 50px !important;
    width: 100% !important;

    background: #fff;

    margin-top: .4rem;

    label {
        position: absolute;
        top: -6px;
        left: 12px;

        padding: 0 4px;
        z-index: 99;

        font-size: .7rem;
        background-color: rgba(255, 255, 255, .8);
        color: #000;
    }

    .autocomplete-container {
        box-shadow: none;
        border: unset;
        height: 48px;

        .input-container {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;

            input {
                padding: 0 16px;
                font-size: 0.8rem;
                color: #000;

                height: 36px;
                line-height: 36px;
            }

        }

        .suggestions-container {
            margin-top: 2px;
            border-radius: 8px;

            ul {
                border-radius: 8px;
                max-height: 152px;

                li.item {
                    a {
                        border-radius: 8px;
                        padding: 0 16px;
                        font-size: 0.8rem;
                        font-weight: 400;
                    }
                }
            }
        }

        .not-found {
            border-radius: 8px;
            border: solid 1px #eee;

            div {
                font-size: 0.8rem;
                padding: 0.8rem 0;
                border-bottom: unset;
            }
        }
    }

    &.small {
        line-height: 37px !important;
        height: 37px !important;

        .autocomplete-container {
            height: 36px;

            .input-container {
                input {
                    line-height: 24px;
                    height: 24px;
                }
            }
        }
    }
}

ion-alert {
    .alert-message {
        font-size: 0.825rem !important;
    }
}

.custom-modal {
    --max-width: 380px;
    --max-height: 380px;
}

.tall-modal {
    --max-width: 380px;
    --max-height: 480px;
}

.banner-modal {
    --max-width: 380px;
    --max-height: 240px;
}

.directory-type-modal {
    --max-width: 380px;
    --max-height: 560px;
}

ion-toast.green-toast {
    --background: rgb(67, 148, 26);
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: #ffffff;
  }

///////////////////////////////////////
/// Floating inputs
//////////////////////////////////////

.athletex-theme {
    form.form-container {
        .floating-input-container {
            position: relative;

            margin-top: .4rem;
            margin-bottom: 1.2rem;

            ion-textarea,
            ion-input {
                color: #000;
                min-height: 50px;
                --border-color: #eee;
                font-size: 0.8rem;

                label {
                    font-size: 0.8rem;
                }
            }

            ion-textarea {
                label {
                    line-height: 1.4;
                }
            }

            ion-input {
                label {
                    line-height: 1.3;
                }
            }

            ion-input.small {
                min-height: 42px;
                label {
                    line-height: 1.1;
                }
            }

            ion-note {
                position: absolute;
                bottom: -15px;
                right: 1px;

                font-size: .6rem;
                margin-left: 4px;

                &.error {
                    color: rgb(158, 0, 0);
                }
            }

            &.disabled {
                ion-input {
                    pointer-events: none;
                    opacity: .5;
                }
            }
        }

    }
}

.auth-horizontal-rule {
    display: flex;
    align-items: center;
    padding: 0 4px;

    margin: 8px 0;

     hr {
        width: 100%;
        background-color: #eee;
     }

     span {
        padding: 0 8px;
     }
}

.profile-complete-container {
    padding-top: 0;
    padding-bottom: 0;
}

ion-toast.status-ok {
    --background: #098a09;
    --color: #fff;
}

ion-toast.status-error {
    --background: #a50d15;
    --color: #fff;
}

.position-picker {
    --max-width: 420px;
    --min-width: 380px;

    --max-height: 480px;
    --min-height: 480px;

    .alert-radio-group.sc-ion-alert-md, .alert-checkbox-group.sc-ion-alert-md {
        max-height: unset;
    }

}

@media screen and (max-width: 599.98px) {
    .section-contrast {
        &.disable-in-sm {
            padding: 0;
            border: unset;
        }
    }
}

@media screen and (min-width: 600px) {
    .custom-select {
        --max-width: 420px;
        --max-height: 420px;

        --min-width: 380px;
    }

    .banner-modal {
        --min-width: 400px;
        --max-width: 520px;
        --max-height: 320px;
    }
}

@media (min-width: 768px) {
    .banner-modal {
        --min-width: 600px;
        --max-width: 720px;
        --max-height: 320px;
    }
  }

  @media (min-width: 992px) {
    .banner-modal {
        --min-width: 800px;
        --max-width: 920px;
        --max-height: 320px;
    }
  }

@media screen and (min-width: 1366px) {
    .profile-complete-container {
        padding: 0 4vw;

        ion-card.form-container {
            padding: 0 2vw;
        }
    }

    .banner-modal {
        --min-width: 1200px;
        --max-width: 1320px;
        --max-height: 360px;
    }
}

@media screen and (min-width: 1920px) {
    .profile-complete-container {
        padding: 0 8vw;

        ion-card.form-container {
            padding: 0 4vw;
        }
    }
}
