.strya-root .w-100 {
  width: 100% !important;
  max-width: 100% !important;
  flex-basis: 100% !important;
}
.strya-root .min-w-33 {
  min-width: 33vw;
}
.strya-root .h-100 {
  height: 100% !important;
  max-height: 100% !important;
}
.strya-root .p-1 {
  padding: 4px !important;
}
.strya-root .p-2 {
  padding: 8px !important;
}
.strya-root .p-3 {
  padding: 12px !important;
}
.strya-root .px-1 {
  padding: 0 4px !important;
}
.strya-root .pl-1 {
  padding: 0 4px 0 0 !important;
}
.strya-root .pb-3 {
  padding: 0 0 12px 0 !important;
}
.strya-root .pb-2 {
  padding: 0 0 8px 0 !important;
}
.strya-root .pb-1 {
  padding: 0 0 4px 0 !important;
}
.strya-root .pt-2 {
  padding: 8px 0 0 0 !important;
}
.strya-root .pt-3 {
  padding: 12px 0 0 0 !important;
}
.strya-root .px-2 {
  padding: 0px 8px !important;
}
.strya-root .px-3 {
  padding: 0px 12px !important;
}
.strya-root .py-1 {
  padding: 4px 0px !important;
}
.strya-root .py-2 {
  padding: 8px 0px !important;
}
.strya-root .py-3 {
  padding: 12px 0px !important;
}
.strya-root .pr-2 {
  padding-right: 8px;
}
.strya-root .mx-1 {
  margin: 0px 4px !important;
}
.strya-root .mx-2 {
  margin: 0px 8px !important;
}
.strya-root .mx-3 {
  margin: 0px 12px !important;
}
.strya-root .my-1 {
  margin: 4px 0px !important;
}
.strya-root .my-2 {
  margin: 8px 0px !important;
}
.strya-root .my-3 {
  margin: 12px 0px !important;
}
.strya-root .mt-3 {
  margin-top: 12px;
}
.strya-root .mr-2 {
  margin-right: 8px;
}
.strya-root .separator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
}
.strya-root .separator span {
  margin: 0 4px;
  font-size: 0.9rem;
}
.strya-root .separator .horizontal-line {
  width: 20%;
  border-bottom: 1px solid #748499;
}
.strya-root .icon-container {
  display: flex;
  align-items: center;
}
.strya-root .icon-container ion-icon {
  margin-right: 4px;
}
.strya-root .box-shadow {
  margin-bottom: 0.6rem;
  -webkit-box-shadow: 0px 8px 6px 0px rgba(68, 68, 68, 0.1);
  box-shadow: 0px 8px 6px 0px rgba(68, 68, 68, 0.1);
}
.strya-root .no-box-shadow {
  box-shadow: unset;
}
@media only screen and (max-width: 599.98px) {
  .strya-root .d-only-mobile-block {
    display: block;
  }
  .strya-root .d-only-moblie-flex {
    display: flex;
  }
  .strya-root .d-only-web-block,
  .strya-root .d-only-web-flex {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  .strya-root .d-only-web-block {
    display: block;
  }
  .strya-root .d-only-web-flex {
    display: flex;
  }
  .strya-root .d-only-mobile-block,
  .strya-root .d-only-moblie-flex {
    display: none;
  }
}
@media only screen and (min-width: 1200px) {
  .strya-root .d-none-lg {
    display: none;
  }
}
.strya-root .d-flex {
  display: flex;
}
.strya-root .f-wrap {
  flex-wrap: wrap;
}
.strya-root .f-d-column {
  flex-direction: column;
}
.strya-root .f-d-column-reverse {
  flex-direction: column-reverse;
}
.strya-root .f-d-row {
  flex-direction: row;
}
.strya-root .a-i-center {
  align-items: center;
}
.strya-root .a-i-end {
  align-items: flex-end;
}
.strya-root .j-c-center {
  justify-content: center;
}
.strya-root .j-c-space-between {
  justify-content: space-between;
}
.strya-root .j-c-right,
.strya-root .j-c-end {
  justify-content: flex-end;
}
.strya-root .all-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.strya-root .athletex-theme ion-grid {
  width: 100%;
}
.strya-root .athletex-theme ion-card {
  box-shadow: none;
  border-radius: 8px;
  border: 1px solid #eee;
}
.strya-root .athletex-theme ion-button {
  --box-shadow: none;
  font-size: 0.7rem;
}
.strya-root .athletex-theme ion-button .button-processing {
  width: 12px;
  height: 12px;
}
.strya-root .athletex-theme ion-select {
  --placeholder-opacity: 0.87;
}
.strya-root .athletex-theme h1 {
  font-weight: 600;
  font-size: 2.4rem;
}
.strya-root .athletex-theme h4 {
  font-weight: 600;
  font-size: 1.6rem;
}
.strya-root .athletex-theme p {
  font-size: 0.9rem;
  margin: 0.2rem 0;
}
.strya-root .athletex-theme .section-contrast {
  background: #f8f8f8;
  border-radius: 8px;
  border: 1px solid #eee;
  padding: 6px 12px;
  margin: 0 0 12px 0;
}
.strya-root .athletex-theme form.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.strya-root .athletex-theme form.form-container label.input-label {
  font-size: 0.8rem;
  color: #000;
  font-weight: 300;
}
.strya-root .athletex-theme form.form-container ion-item.input-container {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 8px;
  border: 1px solid #eee;
}
.strya-root .athletex-theme form.form-container ion-item.input-container ion-note {
  position: absolute;
  bottom: -2px;
  right: 8px;
  z-index: 99;
  color: rgb(150, 0, 0);
}
.strya-root .athletex-theme form.form-container ion-item.input-container ion-input {
  font-size: 0.8rem;
  --color: #000;
  --background: #fff;
}
.strya-root .athletex-theme form.form-container ion-item.input-container ion-select,
.strya-root .athletex-theme form.form-container ion-item.input-container ion-label {
  font-size: 0.8rem;
}
.strya-root .athletex-theme form.form-container ion-item.input-container.item-interactive {
  --border-width: 0;
}
.strya-root .athletex-theme form.form-container ion-item.input-container ion-searchbar {
  padding: 0;
  --box-shadow: 0;
}
.strya-root .athletex-theme form.form-container ion-item.input-container .input-error-message {
  position: absolute;
  bottom: -2px;
  right: 8px;
  font-size: 0.6rem;
  color: rgb(150, 0, 0);
  z-index: 99;
  display: none;
}
.strya-root .athletex-theme form.form-container ion-item.input-container.ion-invalid .input-error-message {
  display: block;
}
.strya-root .athletex-theme form.form-container ion-item.input-container.ion-invalid.without-validation-border {
  border-color: #eee !important;
}
.strya-root .athletex-theme form.form-container ion-item.input-container.readonly {
  --background: #f5f5f5;
}
.strya-root .athletex-theme form.form-container ion-item.input-container.readonly ion-input {
  --background: #f5f5f5;
}
.strya-root .athletex-theme form.form-container .input-helper-message {
  display: block;
  margin: -0.9rem 0.1rem 0.9rem 0.1rem;
  font-size: 0.7rem;
  color: #777;
  text-align: start;
}
.strya-root atx-autocomplete-picker {
  display: block;
}
.strya-root .athlete-x-autocomplete-container {
  position: relative;
  border-radius: 8px;
  border: 1px solid #eee;
  padding: 0px 2px;
  line-height: 50px !important;
  height: 50px !important;
  width: 100% !important;
  background: #fff;
  margin-top: 0.4rem;
}
.strya-root .athlete-x-autocomplete-container label {
  position: absolute;
  top: -6px;
  left: 12px;
  padding: 0 4px;
  z-index: 99;
  font-size: 0.7rem;
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
}
.strya-root .athlete-x-autocomplete-container .autocomplete-container {
  box-shadow: none;
  border: unset;
  height: 48px;
}
.strya-root .athlete-x-autocomplete-container .autocomplete-container .input-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.strya-root .athlete-x-autocomplete-container .autocomplete-container .input-container input {
  padding: 0 16px;
  font-size: 0.8rem;
  color: #000;
  height: 36px;
  line-height: 36px;
}
.strya-root .athlete-x-autocomplete-container .autocomplete-container .suggestions-container {
  margin-top: 2px;
  border-radius: 8px;
}
.strya-root .athlete-x-autocomplete-container .autocomplete-container .suggestions-container ul {
  border-radius: 8px;
  max-height: 152px;
}
.strya-root .athlete-x-autocomplete-container .autocomplete-container .suggestions-container ul li.item a {
  border-radius: 8px;
  padding: 0 16px;
  font-size: 0.8rem;
  font-weight: 400;
}
.strya-root .athlete-x-autocomplete-container .autocomplete-container .not-found {
  border-radius: 8px;
  border: solid 1px #eee;
}
.strya-root .athlete-x-autocomplete-container .autocomplete-container .not-found div {
  font-size: 0.8rem;
  padding: 0.8rem 0;
  border-bottom: unset;
}
.strya-root .athlete-x-autocomplete-container.small {
  line-height: 37px !important;
  height: 37px !important;
}
.strya-root .athlete-x-autocomplete-container.small .autocomplete-container {
  height: 36px;
}
.strya-root .athlete-x-autocomplete-container.small .autocomplete-container .input-container input {
  line-height: 24px;
  height: 24px;
}
.strya-root ion-alert .alert-message {
  font-size: 0.825rem !important;
}
.strya-root .custom-modal {
  --max-width: 380px;
  --max-height: 380px;
}
.strya-root .tall-modal {
  --max-width: 380px;
  --max-height: 480px;
}
.strya-root .banner-modal {
  --max-width: 380px;
  --max-height: 240px;
}
.strya-root .directory-type-modal {
  --max-width: 380px;
  --max-height: 560px;
}
.strya-root ion-toast.green-toast {
  --background: rgb(67, 148, 26);
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  --color: #ffffff;
}
.strya-root .athletex-theme form.form-container .floating-input-container {
  position: relative;
  margin-top: 0.4rem;
  margin-bottom: 1.2rem;
}
.strya-root .athletex-theme form.form-container .floating-input-container ion-textarea,
.strya-root .athletex-theme form.form-container .floating-input-container ion-input {
  color: #000;
  min-height: 50px;
  --border-color: #eee;
  font-size: 0.8rem;
}
.strya-root .athletex-theme form.form-container .floating-input-container ion-textarea label,
.strya-root .athletex-theme form.form-container .floating-input-container ion-input label {
  font-size: 0.8rem;
}
.strya-root .athletex-theme form.form-container .floating-input-container ion-textarea label {
  line-height: 1.4;
}
.strya-root .athletex-theme form.form-container .floating-input-container ion-input label {
  line-height: 1.3;
}
.strya-root .athletex-theme form.form-container .floating-input-container ion-input.small {
  min-height: 42px;
}
.strya-root .athletex-theme form.form-container .floating-input-container ion-input.small label {
  line-height: 1.1;
}
.strya-root .athletex-theme form.form-container .floating-input-container ion-note {
  position: absolute;
  bottom: -15px;
  right: 1px;
  font-size: 0.6rem;
  margin-left: 4px;
}
.strya-root .athletex-theme form.form-container .floating-input-container ion-note.error {
  color: rgb(158, 0, 0);
}
.strya-root .athletex-theme form.form-container .floating-input-container.disabled ion-input {
  pointer-events: none;
  opacity: 0.5;
}
.strya-root .auth-horizontal-rule {
  display: flex;
  align-items: center;
  padding: 0 4px;
  margin: 8px 0;
}
.strya-root .auth-horizontal-rule hr {
  width: 100%;
  background-color: #eee;
}
.strya-root .auth-horizontal-rule span {
  padding: 0 8px;
}
.strya-root .profile-complete-container {
  padding-top: 0;
  padding-bottom: 0;
}
.strya-root ion-toast.status-ok {
  --background: #098a09;
  --color: #fff;
}
.strya-root ion-toast.status-error {
  --background: #a50d15;
  --color: #fff;
}
.strya-root .position-picker {
  --max-width: 420px;
  --min-width: 380px;
  --max-height: 480px;
  --min-height: 480px;
}
.strya-root .position-picker .alert-radio-group.sc-ion-alert-md, .strya-root .position-picker .alert-checkbox-group.sc-ion-alert-md {
  max-height: unset;
}
@media screen and (min-width: 576px) {
  .strya-root .custom-select {
    --max-width: 420px;
    --max-height: 420px;
    --min-width: 380px;
  }
  .strya-root .banner-modal {
    --min-width: 400px;
    --max-width: 520px;
    --max-height: 320px;
  }
}
@media (min-width: 768px) {
  .strya-root .banner-modal {
    --min-width: 600px;
    --max-width: 720px;
    --max-height: 320px;
  }
}
@media (min-width: 992px) {
  .strya-root .banner-modal {
    --min-width: 800px;
    --max-width: 920px;
    --max-height: 320px;
  }
}
@media screen and (min-width: 1366px) {
  .strya-root .profile-complete-container {
    padding: 0 4vw;
  }
  .strya-root .profile-complete-container ion-card.form-container {
    padding: 0 2vw;
  }
  .strya-root .banner-modal {
    --min-width: 1200px;
    --max-width: 1320px;
    --max-height: 360px;
  }
}
@media screen and (min-width: 1920px) {
  .strya-root .profile-complete-container {
    padding: 0 8vw;
  }
  .strya-root .profile-complete-container ion-card.form-container {
    padding: 0 4vw;
  }
}
.strya-root ion-list.sidebar-menu-item ion-list-header {
  font-weight: 400;
  font-size: 0.8rem;
}
.strya-root ion-list.sidebar-menu-item ion-list-header .header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.strya-root ion-list.sidebar-menu-item ion-button.side-menu-button {
  color: #444;
}
.strya-root ion-list.sidebar-menu-item ion-item {
  font-weight: 400;
  font-size: 0.8rem;
  --min-height: 48px;
  cursor: pointer;
}
.strya-root ion-list.sidebar-menu-item ion-item ion-avatar {
  max-height: 32px;
  max-width: 32px;
}
.strya-root ion-list.sidebar-menu-item ion-item h5 {
  font-size: 0.8rem;
  margin: 0 0 0.2rem 0;
}
@media only screen and (max-width: 599.98px) {
  .strya-root ion-list.sidebar-menu-item {
    padding: 4px;
  }
  .strya-root ion-list.sidebar-menu-item ion-list-header .header-container {
    padding-left: 12px;
    font-weight: 600;
  }
}