.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.d-flex {
    display: flex !important;
}

.d-i-table {
    display: inline-table !important;
}

.d-block {
    display: block !important;
}

.f-w-wrap {
    flex-wrap: wrap;
}

.justify-content-space-between,
.j-c-space-between {
    justify-content: space-between;
}

.justify-content-center,
.j-c-center {
    justify-content: center;
}

.j-c-start {
    justify-content: flex-start !important;
}

.j-c-end {
    justify-content: flex-end !important;
}

.flex-column,
.f-d-column {
    flex-direction: column !important;
}

.f-d-row {
    flex-direction: row !important;
}

.align-items-center,
.a-i-center {
    align-items: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

.position-sticky {
    position: sticky;
    top: 0;
    z-index: 999;
}

.gap-0 {
    grid-gap: 0 !important;
}

.gap-1 {
    grid-gap: .5rem !important;
}

.gap-2 {
    grid-gap: 1rem !important;
}

.m-0 {
    margin: 0 !important;
}

.m-1 {
    margin: 0.5rem !important;
}

.m-2 {
    margin: 1rem !important;
}

.m-3 {
    margin: 1.5rem !important;
}

.m-4 {
    margin: 2rem !important;
}

.pr-1 {
    padding-right: 0.5rem !important;
}

.pr-2 {
    padding-right: 1rem !important;
}

.pr-3 {
    padding-right: 1.5rem !important;
}

.pr-4 {
    padding-right: 2rem !important;
}

.pl-1 {
    padding-left: 0.5rem !important;
}

.pl-2 {
    padding-left: 1rem !important;
}

.px-1 {
    padding: 0 0.5rem !important;
}

.px-2 {
    padding: 0 1rem !important;
}

.px-3 {
    padding: 0 1.5rem !important;
}

.px-4 {
    padding: 0 2rem !important;
}

.py-1 {
    padding: 0.5rem 0 !important;
}

.py-2 {
    padding: 1rem 0 !important;
}

.py-3 {
    padding: 1.5rem 0 !important;
}

.py-4 {
    padding: 2rem 0 !important;
}


.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: 0.5rem !important;
}

.p-2 {
    padding: 1rem !important;
}

.p-3 {
    padding: 1.5rem !important;
}

.p-4 {
    padding: 2rem !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-1 {
    padding-bottom: 0.5rem !important;
}

.pb-2 {
    padding-bottom: 1rem !important;
}

.pb-3 {
    padding-bottom: 1.5rem !important;
}

.pb-4 {
    padding-bottom: 2rem !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 0.5rem !important;
}

.mb-2 {
    margin-bottom: 1rem !important;
}

.mb-3 {
    margin-bottom: 1.5rem !important;
}

.mb-4 {
    margin-bottom: 2rem !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 0.5rem !important;
}

.mt-2 {
    margin-top: 1rem !important;
}

.mt-3 {
    margin-top: 1.5rem !important;
}

.mt-4 {
    margin-top: 2rem !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-1 {
    margin-right: 0.5rem !important;
}

.mr-2 {
    margin-right: 1rem !important;
}

.mr-3 {
    margin-right: 1.5rem !important;
}

.mr-4 {
    margin-right: 2rem !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-1 {
    margin-left: 0.5rem !important;
}

.ml-2 {
    margin-left: 1rem !important;
}

.ml-3 {
    margin-left: 1.5rem !important;
}

.ml-4 {
    margin-left: 2rem !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.my-1 {
    margin: 0.5rem 0 !important;
}

.my-2 {
    margin: 1rem 0 !important;
}

.my-3 {
    margin: 1.5rem 0 !important;
}

.my-4 {
    margin: 2rem 0 !important;
}

.mx-1 {
    margin: 0 0.5rem !important;
}

.mx-2 {
    margin: 0 1rem !important;
}

.mx-3 {
    margin: 0 1.5rem !important;
}

.mx-4 {
    margin: 0 2rem !important;
}


.ml-1 {
    margin-left: 0.5rem !important;
}

.ml-2 {
    margin-left: 1rem !important;
}

.ml-3 {
    margin-left: 1.5rem !important;
}

.ml-4 {
    margin-left: 2rem !important;
}

.ml-1 {
    margin-left: 0.5rem !important;
}

.bg-white {
    background-color: #fff;
}

.color-light-grey {
    color: #eee !important;
}

.color-dark-100 {
    color: #2a2a2a !important;
}

.color-dark-200 {
    color: #414141 !important;
}

.color-dark-300 {
    color: #595959 !important;
}

.color-dark-400 {
    color: #717171 !important;
}

.color-dark-500 {
    color: #898989 !important;
}

.color-dark-600 {
    color: #a0a0a0 !important;
}

.color-dark-700 {
    color: #b8b8b8 !important;
}

.color-dark-800 {
    color: #d0d0d0 !important;
}

.color-dark-900 {
    color: #e7e7e7 !important;
}

.color-link-blue {
    color: rgb(0, 110, 213) !important;
}
