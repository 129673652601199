ion-list.sidebar-menu-item {

    ion-list-header {
        font-weight: 400;
        font-size: .8rem;

        .header-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }

    ion-button {
        &.side-menu-button {
            color: #444;
        }
    }

    ion-item {
        font-weight: 400;
        font-size: .8rem;

        --min-height: 48px;

        cursor: pointer;

        ion-avatar {
            max-height: 32px;
            max-width: 32px;
        }

        h5 {
            font-size: .8rem;
            margin: 0 0 .2rem 0;
        }
    }

}


@media only screen and (max-width: 599.98px) {
    ion-list.sidebar-menu-item {
        padding: 4px;

        ion-list-header {
            .header-container {
                padding-left: 12px;
                font-weight: 600;
            }
        }
    }
}
